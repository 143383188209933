<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.sku") }}: {{ sku }}
      <v-spacer></v-spacer>
      <v-btn color="error" outlined @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 450px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th style="width: 120px">
                <SelectWarehouse
                  :label="$t('labels.warehouse_1')"
                  :placeholder="$t('labels.warehouse_1')"
                  name="id_warehouse"
                  sort-name=""
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                  :auto-select="false"
                />
              </th>
              <th style="width: 120px">
                <SelectPos
                  :label="$t('labels.pos')"
                  :placeholder="$t('labels.pos')"
                  name="id_pos"
                  sort-name="pos_code"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                  :auto-select="false"
                />
              </th>
              <th class="text-center">{{ $t("labels.total_in_order") }}</th>
              <th class="text-center">{{ $t("labels.total_handover") }}</th>
              <th class="text-center">Tỷ lệ</th>
              <th class="text-center">
                {{ $t("labels.available_quantity_1") }}
              </th>
              <th class="text-center">
                {{ $t("labels.forecast_out_of_stock") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="`${item.id}_${item.id_warehouse}_${item.id_pos}`"
              class="text-center"
            >
              <td>{{ item.warehouse_code }}</td>
              <td>{{ item.pos_code }}</td>
              <td>{{ formatNumber(item.total_in_order) }}</td>
              <td>{{ formatNumber(item.total_handover) }}</td>
              <td>{{ item.ratio }}</td>
              <td>{{ formatNumber(item.available) }}</td>
              <td>{{ item.forecast }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div>
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import moment from "moment/moment";
const DEFAULT_DATES = [
  moment().subtract("10", "days").format("YYYY-MM-DD"),
  moment().format("YYYY-MM-DD"),
];

export default {
  name: "ReportList",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectPos: () => import("@/components/table/SelectPos"),
  },
  props: {
    sku: {
      type: [String, Number],
      default: () => "",
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    sum: {},
    page: 1,
    total: 0,
    totalPage: 1,
    chartColumns: [],
    filters: {
      sort_by: "available",
      sort_type: "desc",
    },
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.items = [];
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.filters = {
      ...this.filters,
      data_date: [...DEFAULT_DATES],
      forecast_out_of_stock: 2,
      most_recent_period: 10,
      sku: this.sku,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-report-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.sum = { ...data.sum };
          this.items = [...data.rows].map((item) => {
            return {
              ...item,
              total_forecast_handover: +item.total_forecast_handover,
              total_forecast_order: +item.total_forecast_order,
              total_handover: +item.total_handover,
              total_in_order: +item.total_in_order,
            };
          });
        });
    }, 500),

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>
